import { useQuery } from 'react-query';
import { API_URL } from "@/config";

import { axios } from '@/lib/axios';

import { InstitutionResponse } from '../types';
import { GetListBodyStructure, GetListParams, InstitutionSubscriptionTypes } from '@/types';

export const buildInstitutionsListParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    activeSortDirection: string,
    activeSortProperty: string,
    isNumber?: boolean,
    isApproved?: boolean,
    subscription?: InstitutionSubscriptionTypes,
    productId?: number | null,
    feature?: string | null,
) => {
    const filterConditions = [];

    if (activeSearchKeyword) {
        filterConditions.push({
            bool_operator: 'and',
            condition: {
                property: isNumber || Number.isInteger(activeSearchKeyword) ? 'id' : 'title',
                operator: isNumber || Number.isInteger(activeSearchKeyword) ? 'equals' : 'contains',
                value: isNumber || Number.isInteger(activeSearchKeyword)
                    ? Number(activeSearchKeyword)
                    : activeSearchKeyword || '',
            }
        });
    }

    if (isApproved) {
        filterConditions.push({
            bool_operator: 'and',
            condition: {
                property: 'approved',
                operator: 'equals',
                value: true
            }
        });
    }

    if (productId) {
        filterConditions.push({
            bool_operator: 'and',
            condition: {
                property: 'product_id',
                operator: 'equals',
                value: Number(productId)
            }
        });
    }

    if (feature) {
        filterConditions.push({
            bool_operator: 'and',
            condition: {
                property: 'feature',
                operator: 'equals',
                value: feature
            }
        });
    }

    return {
        navigation: {
            page: activePage,
            itemsOnPage: 20
        },
        totalCount: true,
        ...(subscription ? { hasSubscription: subscription } : null),
        filter: {
            collection: filterConditions
        },
        sort: [[activeSortProperty, activeSortDirection]]
    }
}

export const getInstitutions = (data: GetListBodyStructure): Promise<InstitutionResponse> => {
    return axios.post(`${API_URL}/api/admin/institution`, data);
};

export const useInstitutions = ({
    activePage,
    activeSearchKeyword,
    activeSortDirection,
    activeSortProperty,
    isNumber,
    isApproved,
    subscription,
    isRefetch,
    productId,
    feature,
}: GetListParams) => {
    return useQuery<InstitutionResponse, Error>({
        queryKey: [
            'institutions',
            activePage,
            activeSearchKeyword,
            activeSortDirection,
            activeSortProperty,
            isNumber,
            isApproved,
            subscription,
            isRefetch,
            productId,
            feature,
        ],
        queryFn: () => {
            const params = buildInstitutionsListParams(
                activePage,
                activeSearchKeyword,
                activeSortDirection,
                activeSortProperty,
                isNumber,
                isApproved,
                subscription,
                productId,
                feature,
            );
            return getInstitutions(params)
        },
        keepPreviousData: true,
    });
};
